<!--
 * @Author: your name
 * @Date: 2020-10-20 14:14:00
 * @LastEditTime: 2020-10-21 09:47:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \GrandHyattc:\Users\25740\Desktop\jciweb\src\views\AuthPages\RecoverPassword.vue
-->
<template>
  <div class="reset2fa">
    <h4 class="mb-3 mt-5">Google Authenticator</h4>
    <div class="gooogle2FA">
      <div class="d-flex">
        <div class="switchRight">Google Authenticator:</div>
        <b-form-checkbox
          style="display: block"
          switch
          v-model="faVal"
          @change="judgeFa"
        ></b-form-checkbox>
      </div>
      <div>Turn Off Google Authenticator here</div>
      <div class="mt-5">
        <button type="submit" class="btn btn-primary mr-2" @click="reset2fa">
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reset2fa",
  data: () => ({
    token: "",
    email: "",
    is_2fa: "",
    faVal: "",
  }),
  mounted() {
    this.emailGet2faStatus();
  },
  methods: {
    judgeFa() {
      if (this.faVal == true) {
        this.is_2fa = 1;
      } else if (this.faVal == false) {
        this.is_2fa = 0;
      }
    },
    // 判断2fa状态
    emailGet2faStatus() {
      this.params = window.location.href.split("token=")[1];
      this.email = this.params.split("&email=")[1];
      this.$http.emailGet2faStatus({ email: this.email }).then((res) => {
        if (res.status == 200) {
          if (res.data.is_2fa == 0) {
            this.faVal = false;
          } else {
            this.faVal = true;
          }
        }
      });
    },
    // 绑定2fa  开启2fa
    reset2fa() {
      // console.log(this.is_2fa);
      this.params = window.location.href.split("token=")[1];
      this.token = this.params.split("&email=")[0];
      this.email = this.params.split("&email=")[1];
      this.$http
        .reset2fa({
          is_2fa: this.is_2fa,
          user_token: this.token,
          email: this.email,
        })
        .then((res) => {
          if (res.status == 200) {
            localStorage.clear();
            this.$router.push("/auth/sign-in1");
          }
        });
    },
  },
};
</script>


<style lang="scss" scoped>
.reset2fa {
  ::v-deep .table {
    border-color: #e9ecef;
    thead {
      background: #f4f7f9;
      th {
        border-bottom: none;
        border: none;
      }
    }
  }
  margin-top: 20px;
  .titleColor {
    font-size: 16px;
    color: #333333;
    line-height: 24px;
  }
  .gooogle2FA {
    .switchRight {
      margin-right: 140px;
    }
  }
}
</style>
